<template>
   <div>
    <b-row  class="d-flex w-100 justify-content-between">
      <div class="d-flex">
        <h2 class="ml-2">Create a question
        </h2> 
        <feather-icon
        icon="HelpCircleIcon"
        size="24"
        class="ml-50 icono text-warning"
        cursor="help"
        v-b-tooltip.hover
        title="Enter the questions respecting the spelling rules, following the example shown."
        >
        </feather-icon> 
      </div>
        <span v-b-toggle.collapse-1 class="cursor-pointer">
              <tabler-icon :icon="iconIsDown ? 'ChevronUpIcon': 'ChevronDownIcon'" size="20" />
            </span>
        </b-row> 
              <hr>       
<ValidationObserver ref="observer">
  <b-collapse id="collapse-1" class="mt-2" v-model="iconIsDown">
            <b-row class="my-1"  >

                <b-col cols="6">
                    <label for="input-live">Question in english:</label>
                            <b-form-textarea
                            id="input-live"
                            v-model="question.en"
                            placeholder="ex. How satisfied are you with the service provided?"
                            rows="4"
                            ></b-form-textarea>
                </b-col>
                <b-col cols="6">
                    <label for="input-live">Question in spanish:</label>
                            <b-form-textarea
                            id="input-live"
                            v-model="question.es"
                            placeholder="ex. ¿Cuál es su grado de satisfacción con el servicio prestado?"
                            rows="4"
                            ></b-form-textarea>
                </b-col>

                <b-col cols="4">
                    <b-row>
                    <b-col>
                        <label>Weight:</label>
                        <b-form-spinbutton
                            id="sb-step"
                            v-model="weight"
                            min="1"
                            max="5"
                            step="1"
                        />
                        </b-col>
                    </b-row>
                </b-col>
                 <b-col class="text-right mt-2">
                      <b-button
                            size="lg"
                            variant="primary"
                            active: isActive
                            @click="saveItem()"
                            :disabled="!activeSaveIcon"
                            :class="!activeSaveIcon && 'cursor-not-allowed'"
                        >
                        <feather-icon
                          icon="PlusSquareIcon"
                          size="20"
                        />
                            Create
                        </b-button>
                </b-col>
            </b-row>
          </b-collapse>
        </ValidationObserver>

 
   </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      showModal: false,
      isActive: true,
      id: null,
      weight: 1,
      question: {
        en: "",
        es: "",
      },
      iconIsDown: true,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      theme: "appConfig/skin",
    }),
    activeSaveIcon() {
      const { en, es } = this.question;

      return en.trim().length > 0 && es.trim().length > 0;
    },
    params() {
      const { es, en } = this.question;
      const weight = this.weight;

      return {
        spanish: es,
        english: en,
        weight,
      };
    },
  },
  methods: {
    openModal() {
      this.showModal = true;
      this.valueProp();
    },
    closeModal() {
      this.$emit("close");
    },
    clearFields() {
      this.question.es = "";
      this.question.en = "";
      this.weight = 1;
    },
    saveItem() {
      this.$emit("saveQuestion", this.params);
      this.clearFields();
    },
  },
};
</script>
<style scoped>
@keyframes oscillate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* aplicar la animación al icono */
.icono {
  animation-name: oscillate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
</style>
