<template>
  <div class="m-2">
    <div class="d-flex justify-content-end"></div>
    <CreateSurvey
      v-if="showCreateSurvey"
      :active="showCreateSurvey"
      @closeMe="closeCreateSurveyModal"
      @refresh="$refs['surveysTable'].refresh()"  
    />

    <div class="my-1">
      <filter-slot
        :filter="[]"
        :total-rows="FilterSlot.totalRows"
        :paginate="FilterSlot.paginate"
        :start-page="FilterSlot.startPage"
        :to-page="FilterSlot.toPage"
        :filter-principal="FilterSlot.filterPrincipal"
        @reload="$refs['surveysTable'].refresh()"
      >
        <template #buttons v-if="[1, 2, 17].includes(currentUser.role_id)">
          <div class="ml-2">
            <b-button
              variant="primary"
              v-if="[1, 2, 17].includes(currentUser.role_id)"
              @click="openCreateSurveyModal()"
            >
              <tabler-icon icon="PlusIcon" />
              Create New Survey</b-button
            >
          </div>
        </template>
        <b-table
          slot="table"
          ref="surveysTable"
          primary-key="id"
          responsive="md"
          sticky-header="50vh"
          class="blue-scrollbar"
          :items="myProvider"
          :fields="myFields"
          :current-page.sync="FilterSlot.paginate.currentPage"
          :per-page.sync="FilterSlot.paginate.perPage"
          :busy="isBusy"
          no-provider-filtering
          show-empty
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(description_en)="{ item }">
            <div>
               {{ item.description_en }}
            </div>
          </template>
          <template #cell(count_questions)="{ item, value }">
            <div class="d-flex justify-content-center">
               <b-badge class="cursor-pointer" variant="primary" style="font-size: 16px" @click="openDetailsQuestionModal(item.questions)"> {{ value }} </b-badge> 
            </div>
          </template>
          <template #cell(created_by)="{ value, item }">
            <span class="block text-center"> {{ value }}</span> <br />
            <span class="block text-center">
              {{ item.created_at | myGlobalDay }}</span
            >
          </template>
          <template #cell(actions)="{ item, value }">
            <div class="d-flex justify-content-center">
              <FeatherIcon
                icon="EyeIcon"
                @click="openModalDetailsSurvey(item)"
                class="text-primary pointer mr-1"
                size="20"
                v-b-tooltip.hover
                title="View Details"
              />
            </div>
          </template>
        </b-table>
      </filter-slot>
    </div>
  <DetailsQuestionModal
  v-if="showDetailsQuestionsModal"
  :questionSurvey="questionSurvey"
  @close="closeDetailsQuestionModal"
  >
  </DetailsQuestionModal>

  <ModalDetailsSurvey
  v-if="showModalDetailsSurvey"
  :detailsSurvey="detailsSurvey"
  @close="closeModalDetailsSurvey"
  >
  </ModalDetailsSurvey>




    
  </div>
</template>
<script>
import SettingsService from "@/views/quality/views/settings/service/survey.service.js";
import SurveyFields from "@/views/quality/views/settings/views/data/survey.fields.js";
import CreateQuestionModal from "@/views/quality/views/settings/views/modal/createQuestionModal.vue";
import TrackingModal from "./modal/TrackingModal.vue";
import CreateSurvey from "@/views/quality/views/settings/views/modal/CreateSurvey.vue";
import DetailsQuestionModal from "@/views/quality/views/settings/views/modal/DetailsQuestionModal.vue"
import ModalDetailsSurvey from "@/views/quality/views/settings/views/modal/ModalDetailsSurvey.vue"
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      questions: [],
      question: "",
      isBusy: false,
      SurveyFields,
      questionId: null,
      showTrackingModal: false,
      showCreateSurvey: false,
      showDetailsQuestionsModal: false,
      showModalDetailsSurvey: false,
      questionSurvey: null,    
      detailsSurvey : null, 
      FilterSlot: {
        totalRows: 0,
        paginate: {
          currentPage: 1,
          perPage: 10,
        },
        startPage: null,
        toPage: null,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Name...",
          model: "",
        },
      },
    };
  },
  components: {
    "create-question-modal": CreateQuestionModal,
    TrackingModal,
    CreateSurvey,
    DetailsQuestionModal,
    ModalDetailsSurvey,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    myFields: function () {
      return this.SurveyFields.filter((field) => field.visible);
    },
    providerParams() {
      return {
        search: this.FilterSlot.filterPrincipal.model,
        per_page: this.FilterSlot.paginate.perPage ?? 10,
        page: this.FilterSlot.paginate.currentPage,
      };
    },
  },
  methods: {
    async myProvider() {
      this.isBusy = true;

      const { data, total, current_page, from, to } =
        await SettingsService.getAllSurveys(this.providerParams);

      this.isBusy = false;

      this.FilterSlot.totalRows = total;
      this.FilterSlot.paginate.currentPage = current_page;
      this.FilterSlot.startPage = from;
      this.FilterSlot.toPage = to;
      return data;
    },
    openCreateSurveyModal() {
      this.showCreateSurvey = true;
    },
    closeCreateSurveyModal(refresh) {
        if(refresh) this.refreshTable();
        this.showCreateSurvey = false;
    },
    openDetailsQuestionModal(data) {
      this.questionSurvey = data;
      this.showDetailsQuestionsModal = true;
    },
    closeDetailsQuestionModal() {
      this.showDetailsQuestionsModal = false;
    },

    openModalDetailsSurvey(item){
      this.detailsSurvey = item;
      this.showModalDetailsSurvey = true;
    },

    closeModalDetailsSurvey(){
      this.showModalDetailsSurvey = false;
    },



    refreshTable() {
      this.$refs.surveysTable.refresh();
    },
    async changeStatus(data) {
      let status = data.status == "ACTIVE" ? "INACTIVE" : "ACTIVE";
      const formdata = {
        questionId: data.id,
        status: status,
        user: this.currentUser.user_id,
        question: data.question,
        weight: data.weight,
      };
      const confirm = await this.showConfirmSwal(
        `Are you sure to change the status?`
      );
      if (confirm.isConfirmed) {
        try {
          let data = await SettingsService.changeStatus(formdata);
          if (data.success) {
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "Successful operation"
            );
          } else {
            this.showToast(
              "danger",
              "top-right",
              "Failed!",
              "XIcon",
              "Failed operation"
            );
          }
          this.refreshTable();
        } catch (error) {
          this.isActive = true;
          this.showErrorSwal(error);
        }
      }
    },
    closeTrackingModal() {
      this.showTrackingModal = false;
    },
    trackingModal(item) {
      this.questionId = item.id;
      this.showTrackingModal = true;
    },
  },
};
</script>
<style scoped>
.icon_oscilate:hover {
  animation-name: oscillate;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
}

@keyframes oscillate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>