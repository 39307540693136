<template>
  <b-modal
    v-model="showModal"
    size="lg"
    title="List of questions"
    centered
    hide-footer
    @hide="closeModal"
  >
    <div>
      <b-table
        ref="detailQuestionTable"
        :items="myProvider"
        :fields="myFields"
        responsive="sm"
        show-empty
        sticky-header="50vh"
        no-provider-filtering
      >
        <template #cell(en)="{item}">
            <strong>
                {{item.en}}
            </strong>
        </template>
        <template #cell(es)="{item}">
            <strong>
                {{item.es}}
            </strong>
        </template>
        <template #cell(weight)="{item}">
            <b-badge variant="light-primary">
                {{item.weight}}
            </b-badge>
        </template>
      </b-table>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: {
    questionSurvey: String,
  },
  data() {
    return {
      showModal: false,
      myFields: [
        {
          key: "en",
          label: "Question in english",
          tdClass: "pt-1",
        },
        {
          key: "es",
          label: "Question in spanish",
          tdClass: "pt-1",
        },
        {
          key: "weight",
          label: "Weight",
          tdClass: "pt-1 text-center",
          thClass: "text-center",
        },
      ],
      data: null,
    };
  },
  methods: {
    async myProvider() {
      this.data = JSON.parse(this.questionSurvey);
      return this.data;
    },
    closeModal() {
      this.$emit("close");
    },
  },
  created() {
    this.showModal = true;
    this.myProvider();
  },
};
</script>