export default [
    {
        key: "title",
        label: "Title",
        tdClass: "pt-1",
        visible: true,
    },
    {
        key: "description_en",
        label: "Description",
        tdClass: "pt-1",
        visible: true,
        thClass: 'text-left'
    },
    {
        key: "count_questions",
        label: "# of questions",
        tdClass: "pt-1",
        visible: true,
        thClass: 'text-center'
    },
    {
        key: "created_by",
        label: "created by",
        tdClass: "pt-1",
        visible: true,
    },
    {
        key: "Actions",
        label: "actions",
        tdClass: "pt-1",
        visible: true,
        thClass:"text-center" 
    },
]