var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"CREATE SURVEY","title-tag":"h2","size":"xmd","hide-footer":"","scrollable":""},on:{"hidden":function($event){return _vm.closeMe(false)}},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('ValidationObserver',{ref:"form"},[_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('b-card',{attrs:{"bg-variant":_vm.theme}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('h2',[_vm._v("Set a title to survey")]),_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.viewDemo}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"icono",attrs:{"icon":"SmartphoneIcon","size":"24","title":"Preview Survey"}})],1)],1),_c('hr'),_c('b-row',[_c('b-col',{},[_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-0",attrs:{"label":""}},[_c('b-form-input',{attrs:{"placeholder":"ex. First survey","state":errors.length > 0 ? false : null,"rows":"4"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),(errors[0])?_c('div',{staticClass:"text-error-sn text-danger"},[_vm._v("Title "+_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"bg-variant":_vm.theme}},[_c('div',{staticClass:"d-flex"},[_c('h2',[_vm._v("Set a description to show in survey")]),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"ml-50 text-warning icono",attrs:{"icon":"HelpCircleIcon","size":"24","cursor":"help","title":"Enter the description that the survey will carry, the variable @1 will be replaced by the customer's name."}})],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"description_en","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Description in English"}},[_c('b-form-textarea',{attrs:{"placeholder":"ex. Welcome @1! Your opinion matters. Take our short survey and help us improve our services.","state":errors.length > 0 ? false : null,"rows":"4"},model:{value:(_vm.description_en),callback:function ($$v) {_vm.description_en=$$v},expression:"description_en"}})],1),(errors[0])?_c('div',{staticClass:"text-error-sn text-danger"},[_vm._v("Description "+_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"description_es","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Description in Spanish"}},[_c('b-form-textarea',{attrs:{"placeholder":"ex. ¡Bienvenido @1! Tu opinión importa. Responde nuestra breve encuesta y ayúdanos a mejorar nuestros servicios.","state":errors.length > 0 ? false : null,"rows":"4"},model:{value:(_vm.description_es),callback:function ($$v) {_vm.description_es=$$v},expression:"description_es"}})],1),(errors[0])?_c('div',{staticClass:"text-error-sn text-danger"},[_vm._v("Description "+_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-row',{staticClass:"w-100 mt-2"},[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"w-100",attrs:{"label":"VARS"}},[_c('div',{staticClass:"d-flex"},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v("@1")]),_c('b-form-input',{attrs:{"disabled":"","placeholder":"FULL NAME CLIENT","readonly":""}})],1)],1)])],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"bg-variant":_vm.theme}},[_c('CreateQuestionModal',{attrs:{"tabSelected":_vm.tabSelected},on:{"refresh":function($event){return _vm.refreshTable()},"saveQuestion":_vm.setNewQuestion}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"bg-variant":_vm.theme,"text-variant":_vm.theme == 'dark' ? 'dark' : 'white'}},[_c('b-col',[_c('h2',[_vm._v("Questions")])]),_c('b-table',{ref:"allQuestions",staticClass:"blue-scrollbar mt-2",attrs:{"items":_vm.getQuestions,"fields":_vm.myFields,"busy":_vm.isBusy,"variant":'primary',"head-variant":'primary',"no-border-collapse":false,"primary-key":"id","responsive":"md","sticky-header":"50vh","no-provider-filtering":"","show-empty":""},scopedSlots:_vm._u([{key:"cell(english)",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(" "+_vm._s(value))])]}},{key:"cell(action)",fn:function(ref){
var index = ref.index;
return [_c('feather-icon',{staticClass:"text-danger pointer",attrs:{"icon":"TrashIcon","size":"20"},on:{"click":function($event){return _vm.deleteQuestion(index)}}})]}}])})],1)],1),_c('b-col',{staticClass:"text-center mb-2"},[(_vm.surveyIsIncomplete)?_c('div',{staticClass:"text-error-sn text-danger mt-1"},[_vm._v("Please complete the required fields.")]):_vm._e(),_c('b-button',{attrs:{"variant":"success","size":"lg","disabled":!_vm.hasQuestions},on:{"click":_vm.saveSurvey}},[_vm._v(" Create "),_c('FeatherIcon',{attrs:{"icon":"ClipboardIcon","size":"20"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }