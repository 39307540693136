<template>
  <b-modal
    title="CREATE SURVEY"
    title-tag="h2"
    size="xmd"
    v-model="isOpen"
    @hidden="closeMe(false)"
    hide-footer
    scrollable
  >
  <ValidationObserver ref="form">
    <b-row>
      <b-col class="mt-2" cols="12">
        <b-card :bg-variant="theme">
          <div class="d-flex justify-content-between align-items-center">

            <h2>Set a title to survey</h2>
            
            <b-button size="sm" variant="primary" @click="viewDemo">
              <feather-icon
                icon="SmartphoneIcon"
                size="24"
                class="icono"
                v-b-tooltip.hover
                title="Preview Survey">
              </feather-icon>
            </b-button>
        </div>

          <hr>
          <b-row>
            <b-col class="">
              <ValidationProvider v-slot="{errors}" name="title" rules="required">
                <b-form-group label="" class="mb-0">
                  <b-form-input
                  v-model="title"
                  placeholder="ex. First survey"
                  :state="errors.length > 0 ? false : null"
                  rows="4"
                  />
                </b-form-group>
                <div v-if="errors[0]" class="text-error-sn text-danger">Title {{errors[0]}}</div>
              </ValidationProvider>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card :bg-variant="theme">
          <div class="d-flex">
            <h2>Set a description to show in survey</h2>
            <feather-icon
            icon="HelpCircleIcon"
            size="24"
            class="ml-50 text-warning icono"
            cursor="help"
            v-b-tooltip.hover
            title="Enter the description that the survey will carry, the variable @1 will be replaced by the customer's name."
            >
            </feather-icon> 
          </div>
          <hr>
            <b-row>
              <b-col cols="6">
                <ValidationProvider v-slot="{errors}" name="description_en" rules="required">
                  <b-form-group label="Description in English" class="mb-0">
                    <b-form-textarea
                    v-model="description_en"
                    placeholder="ex. Welcome @1! Your opinion matters. Take our short survey and help us improve our services."
                    :state="errors.length > 0 ? false : null"
                    rows=4
                    />
                  </b-form-group>
                  <div v-if="errors[0]" class="text-error-sn text-danger">Description {{errors[0]}}</div>
              </ValidationProvider>
              </b-col>
              <b-col cols="6">  
                <ValidationProvider v-slot="{errors}" name="description_es" rules="required">
                  <b-form-group label="Description in Spanish" class="mb-0">
                    <b-form-textarea
                    v-model="description_es"
                    placeholder="ex. ¡Bienvenido @1! Tu opinión importa. Responde nuestra breve encuesta y ayúdanos a mejorar nuestros servicios."
                    :state="errors.length > 0 ? false : null"
                    rows=4
                    />
                  </b-form-group>
                  <div v-if="errors[0]" class="text-error-sn text-danger">Description {{errors[0]}}</div>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row class="w-100 mt-2">
          <b-col lg="6">
            <b-form-group label="VARS" class="w-100">
              <div class="d-flex">
                <b-input-group size="sm">
                  <b-input-group-prepend is-text>@1</b-input-group-prepend>
                  <b-form-input disabled placeholder="FULL NAME CLIENT" readonly />
                </b-input-group>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card :bg-variant="theme">
          <CreateQuestionModal
            @refresh="refreshTable()"
            @saveQuestion="setNewQuestion"
            :tabSelected="tabSelected"
          />
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card
          :bg-variant="theme"
          :text-variant="theme == 'dark' ? 'dark' : 'white'"
        >
          <b-col>
            <h2>Questions</h2>
          </b-col>

          <b-table
            ref="allQuestions"
            :items="getQuestions"
            :fields="myFields"
            :busy="isBusy"
            :variant="'primary'"
            :head-variant="'primary'"
            :no-border-collapse="false"
            class="blue-scrollbar mt-2"
            primary-key="id"
            responsive="md"
            sticky-header="50vh"
            no-provider-filtering
            show-empty
          >
            <template #cell(english)="{ value }">
              <span> {{ value }}</span>
            </template>
            <template #cell(action)="{ index }">
                <feather-icon
                  @click="deleteQuestion(index)"
                  icon="TrashIcon"
                  class="text-danger pointer"
                  size="20"
                />
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col class="text-center mb-2">
        <div v-if="surveyIsIncomplete" class="text-error-sn text-danger mt-1">Please complete the required fields.</div>
        <b-button
          @click="saveSurvey"
          variant="success"
          size="lg"
          :disabled="!hasQuestions"
        >
          Create <FeatherIcon icon="ClipboardIcon" size="20" />
        </b-button>
      </b-col>
    </b-row>
  </ValidationObserver>
  </b-modal>
</template>

<script>
import SettingsService from "@/views/quality/views/settings/service/survey.service.js";
import SurveyFields from "@/views/quality/views/settings/views/data/survey.fields.js";
import { mapGetters } from "vuex";
import CreateQuestionModal from "@/views/quality/views/settings/views/modal/createQuestionModal.vue";

export default {
  components: {
    CreateQuestionModal,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  async mounted() {
    this.isOpen = this.active;
  },
  data() {
    return {
      isOpen: false,
      draggedItem: null,

      tabSelected: "survey",
      isBusy: false,
      SurveyFields,
      title: "",
      description_en: "",
      description_es: "",
      question: {},
      questions: [],
      surveyIsIncomplete: false,
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
    };
  },
  created() {
  },
  methods: {
    closeMe(refresh = false) {
      this.isOpen = false;
      this.$emit("closeMe", refresh);
    },
    setNewQuestion(question) {
      this.questions.push(question);
    },
    deleteQuestion(index) {
      this.questions.splice(index, 1);
    },
    editQuestion(value) {
      // console.log(value)
    },
    viewDemo(){
      this.$swal.fire({
        imageUrl: `${this.baseImg}/assets/images/survey/preview_survey.svg`,
        imageHeight: 1000,
        imageAlt: 'Image Preview Survey'
      })
    },

    async saveSurvey(){
      let isValid = await this.$refs["form"].validate();
      if (!isValid) {
        this.surveyIsIncomplete = true;
        setTimeout(() => {
          this.surveyIsIncomplete = false;
        }, 3000);
        return;
      }
      try{
        const {isConfirmed} = await this.showConfirmSwal('This will save the survey created', 'Are you sure that the information entered is correct?')

        if(isConfirmed){
          await SettingsService.createSurvey(this.params)
          this.showSuccessSwal('Success','Survey registered successfully')
        }
        
      }catch(error){
        this.showErrorSwal(error)  
      }finally{
        this.closeMe(true)
      }
    
    }
  },
  
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      theme: "appConfig/skin",
    }),
    myFields() {
      const fields = [
        {
          key: "english",
          label: "Question",
          visible: true,
        },
        {
          key: "weight",
          label: "Weight",
          tdClass: "text-center",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "action",
          label: "Action",
          tdClass: "text-center",
          thClass: "text-center",
          visible: true,
        },
      ];
      return fields;
    },
    getQuestions() {
        return this.questions.sort((a, b) => b.weight - a.weight);  
    },
    hasQuestions() {
      return this.questions.length > 0;
    },
    params() {
      const title = this.title;
      const user_id = this.currentUser.user_id;
      const questions = this.questions;
      const description_es = this.description_es;
      const description_en = this.description_en;

      return {
        title,
        user_id,
        questions,
        description_es,
        description_en,
        
      };
    },
  },
};
</script>

<style scoped>
@keyframes oscillate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* aplicar la animación al icono */
.icono {
  animation-name: oscillate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
</style>
