<template>
    <b-modal
      v-model="showModalDetailsSurvey"
      size="lg"
      title="SURVEY DETAILS"
      centered
      hide-footer
      @hide="closeModalDetailsSurvey"
    >
      <div>
        <b-row>
            <b-col class="mt-2" cols="12">
              <b-card :bg-variant="theme">
                  <b-row class="d-flex align-items-center">
                    <b-col>
                        <h4>Title to survey:</h4>
                    </b-col>
                    <b-col lg="9" md="auto">
                      <b-form-group label="" class="mb-0">
                        <b-form-input
                        :value="detailsSurvey.title"
                        readonly
                        rows="4"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
              </b-card>
            </b-col>
          </b-row>
        <b-col cols="12" class="px-0">
          <b-card :bg-variant="theme">
            <b-row>
              <b-col cols="6">
                  <b-form-group label="Description in English" class="mb-0">
                    <b-form-textarea
                    :value="detailsSurvey.description_en"
                    readonly
                    rows=4
                    />
                  </b-form-group>
              </b-col>
              <b-col cols="6">  
                  <b-form-group label="Description in Spanish" class="mb-0">
                    <b-form-textarea
                    :value="detailsSurvey.description_es"
                    readonly
                    rows=4
                    />
                  </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <div>
          <b-card :bg-variant="theme">
          <h4>List of Questions:</h4>
          <hr>
          <b-table
            ref="detailQuestionTable"
            :items="myProvider"
            :fields="myFields"
            responsive="sm"
            show-empty
            sticky-header="50vh"
            no-provider-filtering
          >
            <template #cell(en)="{item}">
                <strong>
                    {{item.en}}
                </strong>
            </template>
            <template #cell(es)="{item}">
                <strong>
                    {{item.es}}
                </strong>
            </template>
            <template #cell(weight)="{item}">
                <b-badge variant="light-primary">
                    {{item.weight}}
                </b-badge>
            </template>
          </b-table>
        </b-card>
        </div>
      </div>
    </b-modal>
</template>


<script>
  import { mapGetters } from "vuex";
  export default {
    props: {
      detailsSurvey: Object,
    },
    
    data() {
      return {
        showModalDetailsSurvey: false,
        questions: null,

        myFields: [
        {
          key: "en",
          label: "Question in english",
          tdClass: "pt-1",
        },
        {
          key: "es",
          label: "Question in spanish",
          tdClass: "pt-1",
        },
        {
          key: "weight",
          label: "Weight",
          tdClass: "pt-1 text-center",
          thClass: "text-center",
        },
      ],

      };
    },
    methods: {

      async myProvider() {
      this.questions = JSON.parse(this.detailsSurvey.questions);

      return this.questions;
    },

      closeModalDetailsSurvey() {
      this.$emit("close");
    },
    },
    async created() {
      this.showModalDetailsSurvey = true;
      this.myProvider();
    },

    computed: {
    ...mapGetters({
      theme: "appConfig/skin",
    })
  },
  };
  </script>